<template>
	<div class="login-page">
		<div class="container center">
			<div class="row">
				<div class="col-12 col-sm-9 col-md-7 col-lg-5 m-auto pt-5">
					<div class="card">
						<div class="card-header bg-white">
							<h3 class="text-center mb-0 text-secondary mt-2">
								{{$conf.logo}}
							</h3>
						</div>
						<div class="card-body">
							<el-form ref="ruleForm" :rules="rules" :model="form" class="form">
								<el-form-item prop="user_name">
									<el-input prefix-icon="el-icon-user-solid" @keyup.enter.native="submit"
										v-model="form.user_name" size="medium" placeholder="请输入用户名"></el-input>
								</el-form-item>
								<el-form-item prop="password">
									<el-input prefix-icon="el-icon-lock" v-model="form.password" size="medium"
										type="password" placeholder="请输入密码" @keyup.enter.native="submit"></el-input>
								</el-form-item>
								<el-form-item prop="code">
									<div class="d-flex center-align">
										<el-input 
											prefix-icon="el-icon-lock" 
											v-model="form.code" 
											size="medium" 
											type="code"
											placeholder="验证码" 
											@keyup.enter.native="submit">
										</el-input>
										<sidentify :identifyCode="login_code" @getCode="getCode" />
									</div>
								</el-form-item>
								<el-form-item>
									<el-button type="danger" size="medium" class="w-100 mt-3" @click="submit"
										:loading="loading">
										{{loading? '登录中...' : '立即登录'}}
									</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	let ms = []
	import {
		mapGetters
	} from 'vuex'
	import cryptoJs from 'crypto-js'
	import sidentify from './components/Sidentify.vue'
	export default {
		components: {sidentify},
		data() {
			return {
				login_code: "",
				loading: false,
				form: {
					user_name: "",
					password: "",
					code: ""
				},
				
				pool: 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789', // 字符串
				
				rules: {
					user_name: [{
						required: true,
						message: "请输入用户名",
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: "请输入验证码",
						trigger: 'blur',
					}]
				}
			}
		},
		computed: {
			...mapGetters([
				'adminIndex'
			])
		},
		created() {
			this.getCode();
		},
		methods: {
			//生成随机数
			randomNum(min, max) {
				return parseInt(Math.random() * (max - min) + min)
			},
			getCode() {
				// this.axios({
				// 	token: true,
				// 	params: {
				// 		s: 'store/passport/login_code',
				// 	},
				// 	method: 'get',
				// }).then(res => {
				// 	this.login_code = res.data.data.code
				// }).catch(err => {
					
				// })
				let imgCode = ''
				for (let i = 0; i < 4; i++) {
					const text = this.pool[this.randomNum(0, this.pool.length)]
					imgCode += text
				}
				this.login_code = imgCode
			},
			submit() {
				const config = {
					key: '0663Messagejncppoiuyhjkl',
					iv: 'jncpMessage0663a',
				}
				this.$refs.ruleForm.validate((e) => {
					if (!e) return;
					
					if(this.form.code !== this.login_code) {
						this.getCode()
						return this.$message('验证码不正确')
					}
					
					// 提交表单
					this.loading = true

					// 加密
					const key = cryptoJs.enc.Latin1.parse(config.key)
					const iv = cryptoJs.enc.Latin1.parse(config.iv)
					const str = cryptoJs.enc.Latin1.parse(JSON.stringify(this.form.password))
					const encrypted = cryptoJs.AES.encrypt(str, key, {
						iv,
						mode: cryptoJs.mode.CBC,
						adding: cryptoJs.pad.ZeroPadding
					})

					const User = {
						user_name: this.form.user_name,
						password: encrypted.toString(),
						code: this.form.code,
					}


					// 请求
					this.axios({
						params: {
							s: 'store/passport/login',
						},
						data: {
							User
						},
						method: 'post',
						withCredentials: true, // 允许携带凭证（cookie）
					}).then(res => {
						this.$message(res.data.msg);
						if (res.data.code == 1) {
							// 登录成功
							// 存储到vuex
							let data = res.data.data
							// 存储到本地存储
							this.$store.commit('login', data)
							// 存储权限规则
							if (data.role && data.role.rules) {
								window.sessionStorage.setItem('rules', JSON.stringify(data.role.rules))
							}
							// 生成后台菜单
							this.$store.commit('createNavBar', data.menus)
							// 成功提示
							this.loading = false
							// 跳转后台首页
							this.$router.push({
								name: this.adminIndex
							})
						} else { this.getCode() }
						this.loading = false
					}).catch(err => {
						this.loading = false
					})
				})
			}
		},
	}
</script>

<style scoped>
	.login-page {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		content: '';
		background: #62a8ea;
		background: url(../../assets/images/login_bg.png) center center/cover no-repeat !important;
	}

	.card-header {
		text-align: center;
		border: none;
	}

	.card-header img {
		width: 3.125rem;
		height: 3.125rem;
	}

	.center {
		position: relative;
		top: 50%;
		/*偏移*/
		transform: translateY(-55%);
	}

	.form {
		padding: 0 2rem 3.125rem;
	}

	.login-page>>>.el-form-item {
		margin: 2rem 0;
	}

	.login-page>>>.el-button--medium {
		padding: 1rem 1.25rem;
		font-size: 1rem;
		border-radius: 0.25rem;
		width: 80%;
	}


	.el-button--danger {
		color: #FFFFFF;
		background-color: #fb6638;
		border-color: #fb6638;
	}

	::v-deep .el-input-group__append {
		padding: 0;
		margin-left: 10px;
	}
</style>